<template>
  <div class="practice-box">
    <div class="container-header">
      <div class="header">
        <!-- <button :class="$style.textButton">
          <font-awesome-icon icon="fa-regular fa-circle-question" />
          <span :class="$style.help">Help</span>
        </button> -->
        <button class="textButton" @click="returnToHome">
          <span class="home">Return to Home</span>
          <i class="fa-solid fa-house-chimney" />
        </button>
      </div>
      <div class="content" v-if="session_class">
        <h1>{{ title }}</h1>
        <div class="directions">
          <el-form label-width="150px" label-position="top">
            <el-form-item label="Class">
              <b class="text-success">
                {{ session_class.name }}
              </b>
            </el-form-item>
            <el-form-item label="Teacher">
              <b
                class="mr-1 text-success"
                v-for="(teacher, index) in session_class.teacher_users"
                :key="teacher.id"
              >
                <template v-if="index < session_class.teacher_users.length - 1">
                  {{
                    `${user.displayName(
                      teacher.first_name,
                      teacher.last_name
                    )},`
                  }}
                </template>
                <template v-else>
                  {{
                    `${user.displayName(teacher.first_name, teacher.last_name)}`
                  }}
                </template>
              </b>
            </el-form-item>
            <el-form-item label="Test Name">
              <el-tag
                v-if="
                  examNow.exam.test_type ===
                    'App\\Entities\\AdaptivePractice'
                "
                class="mr-2"
                type="warning"
              >
                <b>Adaptive</b>
              </el-tag>
              <b class="text-success">
                {{ title }}
              </b>
            </el-form-item>
            <el-form-item
              label="Questions"
              v-if="practices && practices.length > 0"
            >
              <b class="text-success">{{ practices.length }}</b>
              <span v-if="practices.length > 1">
                Sections,
              </span>
              <span v-else>
                Section,
              </span>
              <b class="text-success">
                {{ questionCount }}
              </b>
              Questions
            </el-form-item>
            <el-form-item label="Time" v-if="!isDrill">
              <el-radio-group v-model="timeType">
                <el-radio-button :label="1">
                  Normal Time
                </el-radio-button>
                <el-radio-button :label="1.5">
                  Time and One-Half (+50%)
                </el-radio-button>
                <el-radio-button :label="2">
                  Double Time (+100%)
                </el-radio-button>
                <el-radio-button :label="0">
                  Not Timed (∞)
                </el-radio-button>
              </el-radio-group>
            </el-form-item>
            <!-- <el-form-item label="Rating">
              <el-rate v-model="Rating" text-color="#ff9900" disabled></el-rate>
            </el-form-item> -->
          </el-form>
          <hr />
          <div>
            <template v-if="canStart">
              <div class="text-right">
                <el-button type="primary" @click="startTest">
                  Start
                </el-button>
              </div>
            </template>
            <div class="breakTimeTimerBox" v-else>
              <h4 class="text-center mb-0">
                Starts on
                {{ moment(examNow.exam_date).format("YYYY-MM-DD HH:mm") }}
              </h4>
              <template v-if="showTime">
                <div class="text-center mt-4">Time Left</div>
                <div class="text-center">
                  <div class="breakTimeTimer">
                    <div class="time">
                      {{ getTime(remainExamTimeSeconds) }}
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import SAT from "@/apis/sat.js";
import balance from "@/apis/balance.js";
import role from "@/mixins/role.js";
import Common from "@/mixins/common.js";
import { user, instant } from "@ivy-way/material";
import moment from "moment";
import Class from "@/apis/class.js";

export default {
  metaInfo() {
    return {
      title: "Start - " + this.CompanyName
    };
  },

  components: {},

  mixins: [Common, role],

  props: [],
  data() {
    return {
      moment,
      practices: [],
      practice: null,
      Rating: 5,
      timeType: 1,
      isDrill: false,
      title: null,
      exam: null,
      testId: null,
      remainInterval: null,
      remainExamTimeSeconds: 5,
      remainExamTimeDays: 1,
      canStart: false,
      showTime: false,
      session_class: null,
      practiceType: null,
      examNow: null
    };
  },
  computed: {
    ...mapGetters("user", ["getProfile"]),
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    instant() {
      return instant;
    },
    user() {
      return user;
    },
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    isPro() {
      return this.$store.getters["user/getIsPro"];
    },
    questionCount() {
      let count = 0;
      if (this.practices && this.practices.length > 0) {
        this.practices.forEach(item => {
          count += item.exam.exam_questions.length;
        });
      }
      return count;
    },
    isClass() {
      return true;
    },
    classExamId() {
      return this.$route.params.classExamId;
    }
    // index() {
    //   return this.$route.params.index ? this.$route.params.index : 0;
    // }
  },
  watch: {},

  async mounted() {
    this.getClass();
  },

  methods: {
    async getClass() {
      const res = await Class.getClassTestDetail(this.classExamId);
      this.session_class = res.session_class_exam.session_class;
      // let exam = this.session_class.session_class_exams[
      //   this.$route.params.index
      // ];
      let exam = res.session_class_exam;
      this.examNow = exam;
      if (this.examNow.exam_date) {
        if (
          moment(this.examNow.exam_date).format("YYYY-MM-DD HH:mm") <=
          moment().format("YYYY-MM-DD HH:mm")
        ) {
          this.canStart = true;
        } else {
          const seconds = moment(this.examNow.exam_date).diff(
            moment(),
            "seconds"
          );
          if (seconds <= 86400) {
            this.remainExamTimeSeconds = seconds;
            this.startReciprocal();
            this.showTime = true;
          } else {
            const days = moment(this.examNow.exam_date).diff(moment(), "days");
            this.remainExamTimeDays = days;
          }
        }
      } else {
        this.canStart = true;
      }
      let testId = exam.exam.test_id;
      this.testId = testId;
      if (
        exam.exam.test_type == "Modules\\Practice\\Entities\\ComposePractice"
      ) {
        this.practiceType = "composePractices";
      } else if (exam.exam.test_type == "App\\Entities\\AdaptivePractice") {
        this.practiceType = "adaptivePractices";
      } else {
        this.practiceType = "singlePractice";
      }
      if (testId) {
        if (this.practiceType === "composePractices") {
          const res = await SAT.getComposePracticeTest(testId);
          this.practices = res.compose_practice.practices;
          this.practice = this.practices[0];
          this.exam = res.compose_practice.exam;
          this.title = res.compose_practice.exam.title;
          this.plan = res.compose_practice.exam.coin_plans[0];
          if (res.compose_practice.marks[0].name.en === "new") {
            this.isNew = true;
          }
        }
        if (this.practiceType === "adaptivePractices") {
          const res = await SAT.getAdaptive(testId);
          this.practices = [];
          this.practice = [];
          this.exam = res.adaptive_practice.exam;
          this.title = res.adaptive_practice.exam.title;
          this.plan = res.adaptive_practice.exam.coin_plans[0];
          this.isNew = true;
        } else if (this.practiceType === "singlePractice") {
          const res = await SAT.getPracticeTest(testId);
          this.practices = [res.practice];
          this.practice = res.practice;
          this.exam = res.practice.exam;
          this.title = res.practice.exam.title;
          this.plan = res.practice.exam.coin_plans[0];
          if (res.practice.marks[0].name.en === "new") {
            this.isNew = true;
          }
          if (res.practice.marks[1].name.en === "drill") {
            this.isDrill = true;
          }
        }
      }
    },
    getTime(sec) {
      let currentTime = moment.duration(sec, "seconds");
      return moment({
        h: currentTime.hours(),
        m: currentTime.minutes(),
        s: currentTime.seconds()
      }).format("HH:mm:ss");
    },
    startReciprocal() {
      this.remainInterval = setInterval(() => {
        this.remainExamTimeSeconds -= 1;
        if (this.remainExamTimeSeconds <= 0) {
          clearInterval(this.remainInterval);
          this.canStart = true;
        }
      }, 1000);
    },

    async startTest() {
      let res = null;
      res = await balance.unLockClassExam(this.examNow.id, {
        user_exam: {
          other_status: {
            timeType: this.timeType
          }
        }
      });
      if (res && res.user_exam && res.user_exam.id && res.user_exam.id > 0) {
        this.$router.replace({
          name: this.isPhone ? "SATMobileTest" : "SATTest",
          params: {
            practiceType: this.practiceType,
            id: this.testId
          },
          query: {
            user_exam_id: res.user_exam.id
          }
        });
      }
    },
    returnToHome() {
      this.$router.replace({
        name: "SATList"
      });
    }
  }
};
</script>

<style scoped>
::v-deep .el-form-item__label {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.5rem;
}
::v-deep .el-form-item__content {
  font-size: 1rem;
  line-height: 1.5rem;
  padding-top: 0.5rem;
}
.originCoin {
  font-size: 16px;
  color: #aaa;
  position: relative;
  line-height: 0.8;
  font-weight: 700;
}
.removeLine {
  border-top: 2px solid #aaa;
  position: absolute;
  left: 0px;
  top: 12px;
  transform: rotate(-6deg);
  width: 50px;
}
.practice-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  font-weight: 500;
  /* user-select: none; */
}
</style>
<style lang="scss" scope>
.breakTimeTimerBox {
  background: var(--themeColor);
  border: 1px solid #ffffff;
  color: #ffffff;
  border-radius: 0.5rem;
  padding: 2rem;
}
.breakTimeTimer {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // background: var(--themeColor);

  // padding: 0.5rem;
  width: 45rem;
  height: 9rem;
  margin: 0 auto;
  .time {
    font-weight: bolder;
    font-size: 10rem;
    line-height: 9rem;
  }
}
.card-container {
  background: #2c292c;
  width: 200px;
  height: 200px;
  position: relative;
  perspective: 500px;
  margin: 0 1rem;
}

.card-container::before {
  z-index: 99;
  content: " ";
  position: absolute;
  left: 0;
  top: 50%;
  background: #120f12;
  width: 100%;
  height: 6px;
  margin-top: -3px;
}

.card-item {
  position: absolute;
  width: 100%;
  color: white;
  font-size: 150px;
  text-align: center;
  /* 因为每个卡片只有半个数字，所以高度只有百分之50 */
  height: 50%;
  left: 0;
  top: 0;
  overflow: hidden;
  background: #2c292c;
}

.card1 {
  line-height: 200px;
}
.card2 {
  z-index: 2;
  top: 50%;
  line-height: 0;
  /* 变换原点为上边的中部 */
  transform-origin: center top;
  /* 对折 180 度 */
  transform: rotateX(180deg);
  /* 通过这个属性让元素的背面隐藏 */
  backface-visibility: hidden;
}
.card2:hover {
  transform: rotateX(0);
}

.card3 {
  line-height: 200px;
  transform-origin: center bottom;
  backface-visibility: hidden;
  z-index: 2;
}

.card4 {
  top: 50%;
  line-height: 0;
}
.flip .card2 {
  transform: rotateX(0);
}

.flip .card3 {
  transform: rotateX(-180deg);
}

.textButton {
  background: transparent;
  border: 0rem;
  padding: 0rem;
  font-size: 1rem;
  cursor: pointer;
}
.container-header {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-rows: max-content 1fr;

  .header {
    padding: 1rem 2.5rem 1.2rem 2.5rem;
    display: flex;
    justify-content: space-between;

    .help {
      margin-left: 0.25rem;
    }

    .home {
      margin-right: 0.25rem;
    }
  }

  .content {
    background: #faf9fa;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;

    h1 {
      font-weight: 400;
      margin-bottom: 2rem;
    }

    .directions {
      background: #ffffff;
      padding: 2rem 3rem;
      box-shadow: 0 0.5rem 1rem #dcdcdc;
      min-width: 850px;
      // h2 {
      //   font-size: 1.2rem;
      //   margin-bottom: 2rem;
      //   font-weight: 400;
      // }

      .tools {
        // display: grid;
        // grid-gap: 1rem;
        // grid-template-columns: repeat(2, 1fr);
        // grid-template-rows: repeat(3, 1fr);

        .tool {
          display: grid;
          grid-template-columns: 3rem 1fr;
          grid-gap: 1rem;
          width: 40rem;
          min-height: 5.5rem;
          margin-bottom: 2rem;

          .toolIcon {
            border-radius: 2rem;
            width: 2.5rem;
            height: 2.5rem;
            font-size: 1.2rem;
            background: #dcdcdc;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          h3 {
            font-weight: 700;
            margin-top: 0;
            margin-bottom: 1rem;
          }

          p {
            margin-top: -0.2rem;
            line-height: 1.6rem;
          }
        }
      }
    }
  }

  .footer {
    border-top: 0.25rem solid #e9e9e9;
    padding: 0 2.5rem;
    display: grid;
    grid-template-columns: 3fr 8fr 3fr;
    grid-gap: 2rem;
    justify-content: space-between;
    align-items: center;

    .stepButton {
      font-weight: bold;
      padding: 0.5rem 3rem;
      border-radius: 1rem;
      border: 0.1rem var(--themeColor) solid;
      cursor: pointer;
    }

    .back {
      background: #ffffff;
    }

    .right {
      text-align: right;
      .next {
        background: var(--themeColor);
        color: white;
      }
    }

    .step {
      text-align: center;
      justify-self: center;
      width: 100%;

      .stepBottomLine {
        margin-top: 0.8rem;
        width: 100%;
        border-top: 0.4rem solid #e7f1f9;
        border-radius: 0.2rem;
        position: relative;

        .stepLine {
          position: absolute;
          border-top: 0.4rem solid var(--themeColor);
          border-radius: 0.2rem;
          top: -0.4rem;
          left: 0rem;
          transition: width 0.5s;
        }
      }
    }
  }
}
</style>
